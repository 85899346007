<template>
  <div class="register">
    <div class="header_top">
      <img
        src="../../assets/images/jingyun_word.png"
        alt=""
        class="word_log"
        @click="$router.push({path:'/index'})"
      >
      <div class="page_title">
        <span>•</span>
        <span>用户注册</span>
      </div>
    </div>
    <div class="steps_box">
      <div class="steps">
        <el-steps
          :active="steps_active"
          finish-status="success"
        >
          <el-step title="填写注册信息"></el-step>
          <el-step title="注册完成"></el-step>
        </el-steps>
      </div>
    </div>
    <div
      class="out_container"
      v-if="steps_active!==2"
    >

      <div class="input_arr">
        <el-form
          :model="info"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item prop="phone">
            <el-input
              v-model.number="info.phone"
              placeholder="请输入常用手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="code">
              <el-input
                v-model="info.code"
                placeholder="请输入短信验证码"
              ></el-input>
              <el-button
                type="primary"
                @click="aviod_code(info.phone)"
                :disabled="time_type"
              ><span v-if="time_type">{{set_time}}s</span>{{reg_word}}</el-button>
            </div>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              v-model="info.password"
              placeholder="密码(6-16位数字和字母组合)"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sure_password">
            <el-input
              v-model="info.sure_password"
              placeholder="确认密码"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="text_row">

        <el-checkbox v-model="checked">我已阅读并同意<span
            class="news"
            @click="go_user(1)"
          >《用户服务协议》</span>和<span
            class="news"
            @click="go_user(2)"
          >《隐私政策》</span></el-checkbox>
      </div>
      <div class="btm_btn">
        <el-button
          type="primary"
          @click="to_register('ruleForm')"
        >立即注册</el-button>
      </div>
    </div>
    <div
      class="out_container"
      v-if="steps_active===2"
    >
      <div class="content">
        <span>注册成功</span>
        <span>请使用你的账号 {{info.phone}} 进行登录</span>
        <span>{{time}}秒 后自动登录并跳转至首页</span>
        <div class="btm_btn">
          <el-button
            type="primary"
            @click="go_index()"
          >立即跳转</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { register, send_captcha } from "@/https/api.js"
import { Moneyinfo } from "@/https/mine_center.js"
export default {
  name: 'register',
  components: {},
  data () {
    return {
      input: '',
      steps_active: 0,
      checked: false,
      time_type: false,
      time: 5,
      set_time: 60,
      set_time_out: null,
      time_init: null,
      reg_word: '获取验证码',
      info: {
        phone: '',
        code: '',
        password: '',
        sure_password: ''
      },
      rules: {
        phone: [{ required: true, type: 'number', message: '手机号不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' },
        { required: true, max: 16, min: 6, message: '密码长度在6到16个字符', trigger: 'blur' },
        { required: true, validator: this.rule_password, trigger: 'blur' }],
        sure_password: [{ required: true, message: '确认密码不能为空', trigger: 'blur' },
        { required: true, validator: this.checkpassword, trigger: 'blur' }]
      }
    }
  },
  watch: {
    steps_active (n, v) {
      if (n === 2) {
        this.init_time()
      }
    }
  },
  methods: {
    checkpassword (rule, value, callback) {
      if (this.info.password !== value) {
        callback(new Error('两次密码不同请重新输入'));
      }
      callback()
    },
    rule_password (rule, value, callback) {
      var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (!reg.test(value)) {
        callback(new Error('密码必须包含字母和数字'));
      }
      callback()
    },
    aviod_code (num) {
      let props = {
        phone: num
      }
      let is_phone=(/^1(3|4|5|6|7|8|9)\d{9}$/g.test(num))
      let that=this
      if(is_phone){
        send_captcha(props).then((res)=>{
          if(res.code===0){
            this.time_type=true
            this.reg_word="重新获取验证码"
            this.time_init=setInterval(()=>{
              if(that.set_time>=1){
                that.set_time-=1
              }else{
                this.set_time=60
                that.time_type=false
                that.code_clear_time()
              }
            }, 1000)
          } else {
            this.$message.warning(res.msg)
          }
        })
      } else {
        this.$message.warning("手机号码格式有误，请重填")
      }
    },

    code_clear_time () {
      clearInterval(this.time_init)
      this.time_init = null
    },
    to_register (formName) {
      let props = {
        phone: this.info.phone,
        password: this.info.password,
        captcha: this.info.code
      }
      if (this.checked) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            register(props).then((res) => {
              if (res.code === 0) {
                localStorage.setItem("api_token", res.data)
                // this.setCookie("api_token", res.data)
                this.get_mine_info()
                this.steps_active = 2
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$message.warning("请确认阅读用户使用协议和隐私协议")
      }
    },
    get_mine_info () {
      Moneyinfo().then(res => {
        if (res.code === 0) {
          sessionStorage.setItem("person_info", JSON.stringify(res.data))
        }
      })
    },
    go_index () {
      this.$router.push({
        path: '/index'
      })
    },
    init_time () {
      this.set_time_out = setInterval(() => {
        if (this.time > 0) {
          this.time -= 1
        } else {
          this.time = 5
          this.clear_time()
          this.$router.push({
            path: "/index"
          })
        }
      }, 1000)
    },
    clear_time () {
      clearInterval(this.set_time_out)
      this.set_time_out = null
    },
    go_user (num) {
      if (num === 1) {
        // const href = this.$router.resolve({
        //   path:'/service'
        // })
        // window.open(href,"_blank")
        const { href } = this.$router.resolve({
          path: "/service"
        });
        window.open(href, "_blank");
        // this.$router.push({
        //   path:'/service'
        // })
      }
      if (num === 2) {
        const { href } = this.$router.resolve({
          path: "/privacy"
        });
        window.open(href, "_blank");
      }
    }
  },
  beforeDestroy () {
    this.code_clear_time()
    this.clear_time()
  }
}
</script>
<style lang="scss" scoped>
@import '../../utils/tool.scss';
// @include flex-center();
.register {
  width: 1200px;
  margin: 0 auto;
  @include flex-column-center();
}

.header_top {
  @include flex-row();
  width: 100%;
  padding: 30px;
  .word_log {
    width: 140px;
    height: 70px;
    cursor: pointer;
  }
  .page_title {
    font-size: 28px;
    color: #99ccff;
    margin-left: 50px;
    span:nth-child(1) {
      padding: 0 20px;
    }
  }
}

.steps_box {
  @include flex-row();
  justify-content: center;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
}
.steps {
  width: 450px;
}
.out_container {
  padding: 50px 0;
  @include flex-column-center();
  /deep/.el-input__inner {
    height: 40px !important;
    line-height: 40px !important;
    border-radius: 0;
    //  margin: 10px 0;
  }
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
  .input_arr {
    @include flex-column-center();
    /deep/.el-input {
      width: 470px;
    }
    .code {
      @include flex-row;
      /deep/.el-button {
        border-radius: 0;
        width: 170px;
      }
      /deep/.el-input {
        width: 300px;
      }
    }
  }
}
.text_row {
  padding: 10px 0;
}
.btm_btn {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
.content {
  @include flex-column-center();
  span:nth-child(1) {
    font-size: 20px;
    font-weight: bold;
  }
  span {
    padding: 30px 0;
  }
}
/deep/.is-process {
  color: #666666 !important;
  border-color: #666666 !important;
  /deep/.el-step__line-inner {
    color: #666666 !important;
  }
  /deep/.el-step__icon {
    color: #666666 !important;
    border-color: #666666 !important;
  }
}

/deep/.is-success {
  color: #409eff !important;
  border-color: #409eff !important;
  /deep/.el-step__line-inner {
    color: #409eff !important;
  }
  /deep/.el-step__icon {
    color: #409eff !important;
    border-color: #409eff !important;
  }
}
.news {
  color: #409eff;
}
</style>

